/*************************************/
/* BELOW 1152px (Landscape tablets) */
/*************************************/

@media (max-width: 72em) {
  html {
    /* 9px / 16px  */
    font-size: 56.25%;
  }
}

/*************************************/
/* BELOW 960px (Tablets) */
/*************************************/

@media (max-width: 60em) {
  html {
    /* 8px / 16px  */
    font-size: 50%;
  }

  .heading-primary {
    font-size: 5.16rem;
    margin-bottom: 4.8rem;
  }

  .hero-container {
    grid-template-columns: 1.5fr 1fr;
    row-gap: 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

  .hero-underline {
    bottom: -30%;
  }

  .section-vision {
    padding-top: 9.6rem;
    padding-bottom: 6.4rem;
  }

  .heading-tertiary {
    font-size: 2.986rem;
    margin-bottom: 3.2rem;
  }

  .vision-grid {
    grid-template-columns: 1fr 1.5fr 1.5fr;
    column-gap: 3.2rem;
    row-gap: 12.8rem;
  }

  .vision-svg {
    width: 100%;
  }

  .vision-accent-bar {
    height: 0.5rem;
    width: 4.8rem;
    margin-bottom: 1.6rem;
  }

  .vision-text {
    line-height: 1.4;
  }

  .heading-secondary {
    font-size: 4.8rem;
    margin-bottom: 4.8rem;
  }

  .cta {
    grid-template-columns: 1.4fr 1fr;
  }

  .cta-form {
    grid-template-columns: 1fr 4fr;
  }

  .cta-text-box {
    padding: 3.2rem;
  }

  .footer {
    padding-top: 3.2rem;
    padding-bottom: 4.8rem;
  }

  .footer-list {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    line-height: 1.6;
  }

  .footer-list:before {
    content: '';
    display: block;
    height: 0.1rem;
    width: 100%;
    background-color: #eee;
  }

  .footer-icon {
    height: 3.2rem;
    width: 3.2rem;
  }

  .footer-link:hover,
  .footer-link:active {
    color: inherit;
  }
}

/*************************************/
/* BELOW 624px (Small Tablets) */
/*************************************/

/* Reminder: update sticky nav in JS when changing threshold */
@media (max-width: 39em) {
  .heading-primary {
    margin-bottom: 6.4rem;
  }

  .header {
    padding: 0 4.8rem;
  }

  .nav-hiring {
    display: none;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 1.8rem 3.6rem;
    font-size: 2.074rem;
  }

  .btn--solid:hover,
  .btn--solid:active {
    background-color: var(--gray-dark);
    color: #fff;
    box-shadow: none;
  }

  .btn--mobile-hidden {
    visibility: hidden;
  }

  .section-hero {
    padding-top: 6.4rem;
    padding-bottom: 4.8rem;
  }

  .hero-container {
    grid-template-columns: 1fr;
    padding: 0 3.2rem;
  }

  .hero-text-box {
    text-align: center;
  }

  .hero-underline {
    bottom: -27%;
  }

  .hero-body-text {
    padding: 0 2.4rem;
  }

  .hero-underline {
    bottom: -30%;
  }

  .hero-img {
    padding-top: 2.4rem;
    max-height: 50rem;
    width: auto;
  }

  .container {
    padding: 1.6rem;
  }

  .section-vision {
    padding-top: 1.6rem;
  }

  .vision-grid {
    grid-template-columns: 1fr;
    row-gap: 5.2rem;
  }

  .heading-tertiary {
    font-size: 3.583rem;
  }

  .vision-row-highlight {
    grid-row: 5 / 7;
    height: 108%;
  }

  .vision-svg-box {
    margin: 6.4rem 4.8rem 0 4.8rem;
  }

  .vision-svg {
    max-width: 45rem;
  }

  .vision-text-box {
    grid-column: 1;
  }

  .section-cta {
    padding: 6.4rem 0;
  }

  .heading-secondary {
    font-size: 4.3rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-text {
    margin-bottom: 4.8rem;
  }

  .cta-img-box {
    height: 34rem;
    grid-row: 1;
  }

  .footer {
    padding-top: 2.4rem;
    padding-bottom: 3.2rem;
  }

  .opacity-zero {
    opacity: 1;
  }

  .fade-in {
    animation: none;
  }

  .slide-in {
    animation: none;
  }

  .beta-hero-body {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }

  .beta-svg {
    width: 65%;
    justify-self: center;
  }

  .beta-hero-body-text {
    grid-column: 1;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .section-os {
    padding-top: 9.6rem;
  }

  .os-container {
    grid-template-columns: 1fr;
    row-gap: 4.2rem;
  }

  .beta-os-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.2rem;
  }

  .beta-margin-bottom {
    margin-bottom: 0;
    margin-top: 4.2rem;
  }
}
